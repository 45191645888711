import { type Theme } from "@mui/material";
import { type Components } from "@mui/material/styles";

export const muiToggleButtonOverrides: Components<Theme> = {
  MuiToggleButtonGroup: {
    styleOverrides: {
      root: ({ theme }) => ({
        borderRadius: theme.borderRadius?.large,
        border: "none",
        padding: theme.spacing(1),
        justifyContent: "space-between",
        width: "-webkit-fill-available",
        fontSize: theme.typography.body2.fontSize,
      }),
    },
  },
  MuiToggleButton: {
    styleOverrides: {
      root: ({ theme }) => ({
        textTransform: "none",
        padding: `${theme.spacing(4)} ${theme.spacing(5)}`,
        borderRadius: `${String(theme.borderRadius?.xLarge)} !important`,
        border: `${String(theme.borderWidth?.regular)} solid ${String(
          theme.palette.border?.primary
        )} !important`,
        backgroundColor: theme.palette.background.tertiary,
        color: theme.palette.primary?.main,
        height: theme.size?.button?.small.height,

        "&:hover": {
          backgroundColor: theme.palette.background.tertiary,
        },

        "@media (hover: hover)": {
          "&:hover": {
            border: `${theme.borderWidth?.regular} solid ${String(
              theme.palette.primary?.main
            )} !important`,
          },
        },

        "&.Mui-selected": {
          border: `${theme.borderWidth?.regular} solid ${String(
            theme.palette.primary?.main
          )} !important`,
          backgroundColor: theme.palette.background.tertiary,

          "&:hover": {
            backgroundColor: theme.palette.background.tertiary,
          },
        },

        "&.Mui-disabled": {
          color: theme.palette.intent?.disabled.text,
          border: `${theme.borderWidth?.regular} solid ${String(
            theme.palette.intent?.disabled.border
          )} !important`,
        },
      }),
    },
  },
};
