import { type Theme } from "@mui/material";
import { type Components } from "@mui/material/styles";

export const muiListOverrides: Components<Theme> = {
  MuiList: {
    styleOverrides: {
      root: {
        padding: 0,

        "& .MuiListItemIcon-root": {
          minWidth: "2rem",

          "& svg": {
            width: "1.5rem",
            height: "1.5rem",
          },
        },
      },
      dense: {
        "& .MuiListItemIcon-root": {
          minWidth: "1.5rem",

          "& svg": {
            width: "1rem",
            height: "1rem",
          },
        },
      },
    },
  },
  MuiListItem: {
    styleOverrides: {
      root: ({ theme }) => ({
        padding: theme.spacing(7, 0),

        "&.Mui-disabled": {
          opacity: 1,
          color: theme.palette.intent?.disabled.text,
        },
      }),
      dense: ({ theme }) => ({
        padding: theme.spacing(2, 0),
      }),
    },
  },
  MuiListItemButton: {
    styleOverrides: {
      root: ({ theme }) => ({
        padding: theme.spacing(7, 0),
        borderRadius: 0,

        // this is a small adjustment to align with primary text
        ".call-to-action-icon": {
          marginTop: "0.125rem",
        },

        "@media (hover: hover)": {
          "&:hover, &:focus": {
            backgroundColor: "initial",

            ".call-to-action-icon": {
              transform: "scale(1.2)",
            },
          },
        },

        "&.Mui-disabled": {
          opacity: 1,
          color: theme.palette.intent?.disabled.text,

          "& .MuiTypography-root": {
            color: `${String(theme.palette.intent?.disabled.text)} !important`,
          },
        },
      }),
      dense: ({ theme }) => ({
        ".call-to-action-icon": {
          marginTop: 0,
        },

        padding: theme.spacing(2, 0),
      }),
    },
  },
  MuiListItemIcon: {
    styleOverrides: {
      root: ({ theme }) => ({
        color: "inherit",
        alignSelf: "flex-start",

        "&.Mui-disabled": {
          color: theme.palette.intent?.disabled.icon,
        },
      }),
    },
  },
  MuiListItemText: {
    styleOverrides: {
      root: ({ theme }) => ({
        margin: 0,

        "& .MuiListItemText-primary": {
          color: theme.palette.text.primary,
          fontSize: theme.typography.h5.fontSize,
          fontWeight: theme.typography.fontWeightMedium,
          letterSpacing: theme.typography.h5.letterSpacing,
          lineHeight: theme.typography.h5.lineHeight,
        },

        "& .MuiListItemText-secondary": {
          marginTop: theme.spacing(1),
          color: theme.palette.text.primary,
          fontSize: theme.typography.body2.fontSize,
          fontWeight: theme.typography.fontWeightMedium,
          letterSpacing: theme.typography.body2.letterSpacing,
          lineHeight: theme.typography.body2.lineHeight,
        },

        // dense is not a property on MuiListItemText, but this class is passed down when dense is true on parent item
        "&.MuiListItemText-dense": {
          "& .MuiListItemText-primary": {
            fontSize: theme.typography.body2.fontSize,
            fontWeight: theme.typography.fontWeightMedium,
            letterSpacing: theme.typography.body2.letterSpacing,
            lineHeight: theme.typography.body2.lineHeight,
          },

          "& .MuiListItemText-secondary": {
            color: theme.palette.intent?.disabled.text,
          },
        },
      }),
    },
  },
};
