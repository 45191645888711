import { type Theme } from "@mui/material";
import { type Components } from "@mui/material/styles";

declare module "@mui/material/InputBase" {
  interface InputBasePropsSizeOverrides {
    small: true;
    medium: true;
    large: true;
  }
}

export const muiInputOverrides: Components<Theme> = {
  MuiOutlinedInput: {
    defaultProps: {
      size: "medium",
      fullWidth: true,
    },
    styleOverrides: {
      root: ({ theme }) => ({
        fontSize: theme.typography.body2.fontSize,
        color: theme.palette.text?.primary,
        backgroundColor: theme.palette.background.tertiary,

        input: {
          boxSizing: "border-box",

          "&.MuiInputBase-inputAdornedStart": {
            paddingLeft: theme.spacing(3),
          },

          "&::placeholder": {
            WebkitTextFillColor: theme.palette.text?.tertiary,
            opacity: 1,
          },

          "&.Mui-disabled::placeholder": {
            WebkitTextFillColor: theme.palette.intent?.disabled.text,
          },
        },

        "& .MuiOutlinedInput-notchedOutline": {
          borderColor: theme.palette.border?.primary,
        },

        "&.Mui-focused": {
          "&:not(.MuiInputBase-multiline)": {
            boxShadow: theme.shadows[2],
          },

          "& .MuiOutlinedInput-notchedOutline": {
            borderWidth: theme.borderWidth?.regular,
            borderColor: theme.palette.primary?.main,
          },
        },

        "&.Mui-disabled": {
          color: theme.palette.intent?.disabled.text,
          borderColor: theme.palette.intent?.disabled.border,

          "&.MuiInputBase-adornedStart svg": {
            color: theme.palette.intent?.disabled.icon,
          },

          "& .MuiOutlinedInput-notchedOutline": {
            borderColor: theme.palette.intent?.disabled.border,
          },
        },
      }),
    },

    variants: [
      {
        props: { size: "small" },
        style: ({ theme }) => ({
          borderRadius: theme.borderRadius?.small,

          input: {
            padding: theme.spacing(4),
            height: theme.size?.input?.small,
          },
        }),
      },
      {
        props: { size: "medium" },
        style: ({ theme }) => ({
          borderRadius: theme.borderRadius?.small,

          input: {
            padding: theme.spacing(5),
            height: theme.size?.input?.medium,
          },
        }),
      },
      {
        props: { size: "large" },
        style: ({ theme }) => ({
          borderRadius: theme.borderRadius?.medium,
          fontSize: theme.typography.body1.fontSize,

          input: {
            padding: theme.spacing(7),
            height: theme.size?.input?.large,
          },
        }),
      },
    ],
  },
};
