import { type Theme } from "@mui/material";
import { type Components } from "@mui/material/styles";

export const muiChipOverrides: Components<Theme> = {
  MuiChip: {
    styleOverrides: {
      root: ({ theme }) => ({
        "& .MuiChip-icon, & .MuiChip-label, & .MuiTypography-root": {
          color: theme.palette.text?.primary,
          margin: 0,
          padding: 0,
        },

        "& > *:last-child:not(:only-child):not(:empty)": {
          marginLeft: theme.spacing(2),
        },

        "&.Mui-disabled": {
          opacity: 1,

          "& .MuiChip-icon, & .MuiChip-label, & .MuiTypography-root": {
            color: theme.palette.intent?.disabled.text,
          },
        },
      }),
      sizeSmall: ({ theme }: { theme: Theme }) => ({
        height: theme.size?.pill?.small,
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),
        "& .MuiChip-label, & .MuiTypography-root": {
          fontSize: theme.typography.caption.fontSize,
        },
      }),

      sizeMedium: ({ theme }: { theme: Theme }) => ({
        height: theme.size?.pill?.medium,
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
        paddingLeft: theme.spacing(4),
        paddingRight: theme.spacing(4),
        "& .MuiChip-label, & .MuiTypography-root": {
          fontSize: theme.typography.body2.fontSize,
        },
      }),
    },

    variants: [
      {
        props: { variant: "filled" },
        style: ({ theme }) => ({
          backgroundColor: theme.palette.background?.primary,

          "&.Mui-disabled": {
            backgroundColor: theme.palette.intent?.disabled.background,
          },
        }),
      },
      {
        props: { variant: "outlined" },
        style: ({ theme }) => ({
          border: `${theme.borderWidth?.thin} solid ${String(theme.palette.border?.primary)}`,

          "&.Mui-disabled": {
            borderColor: theme.palette.intent?.disabled.border,
          },
        }),
      },
    ],
  },
};
