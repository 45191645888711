import { type Components, type Theme } from "@mui/material/styles";

export const muiSkeletonOverrides: Components<Theme> = {
  MuiSkeleton: {
    styleOverrides: {
      rounded: ({ theme }) => ({
        borderRadius: theme.borderRadius?.small,
      }),
    },
  },
};
