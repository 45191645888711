import { type Theme } from "@mui/material";
import { type Components } from "@mui/material/styles";

import { applyPlatformAwareInteractionStyles } from "../../utils/applyPlatformAwareInteractionStyles";

declare module "@mui/material/IconButton" {
  interface IconButtonPropsSizeOverrides {
    xSmall: true;
  }

  interface IconButtonOwnProps {
    /**
     * Inverts the button colors.
     *
     * Note: this can't be a boolean because MUI passes it down to the
     * HTML element, which doesn't support boolean values and shows errors
     * in the console.
     */
    invert?: "true" | "false";
    /**
     * For outlined buttons, this controls the thickness of the outline.
     *
     * Defaults to "regular"
     */
    outlineThickness?: "thick" | "regular";
  }
}

/**
 * Use IconButton from src/appV2/ShiftDiscovery/components/IconButton.tsx
 * instead of MUI IconButton.
 */
export const muiIconButtonOverrides: Components<Theme> = {
  MuiIconButton: {
    styleOverrides: {
      root: ({ theme }) => ({
        // MUI's IconButton has default padding for the icon,
        // but we are using our custom CBHIcon component,
        // so we need to clean that up.
        padding: 0,

        variants: [
          {
            props: { size: "xSmall" },
            style: {
              width: theme.size?.iconButton.xSmall.size,
              height: theme.size?.iconButton.xSmall.size,

              "& svg": {
                width: theme.size?.iconButton.xSmall.iconSize,
                height: theme.size?.iconButton.xSmall.iconSize,
              },
            },
          },
          {
            props: { size: "small" },
            style: {
              width: theme.size?.iconButton.small.size,
              height: theme.size?.iconButton.small.size,

              "& svg": {
                width: theme.size?.iconButton.small.iconSize,
                height: theme.size?.iconButton.small.iconSize,
              },
            },
          },
          {
            props: { size: "medium" },
            style: {
              width: theme.size?.iconButton.medium.size,
              height: theme.size?.iconButton.medium.size,

              "& svg": {
                width: theme.size?.iconButton.medium.iconSize,
                height: theme.size?.iconButton.medium.iconSize,
              },
            },
          },
          {
            props: { size: "large" },
            style: {
              width: theme.size?.iconButton.large.size,
              height: theme.size?.iconButton.large.size,

              "& svg": {
                width: theme.size?.iconButton.large.iconSize,
                height: theme.size?.iconButton.large.iconSize,
              },
            },
          },
        ],
      }),

      // Used for "contained" variant
      colorPrimary: ({ theme }) => ({
        color: theme.palette.text.invertPrimary,
        backgroundColor: theme.palette.primary.main,

        ...applyPlatformAwareInteractionStyles({
          hoverStyle: {
            backgroundColor: theme.palette.primary.dark,
          },
          defaultStyle: {
            backgroundColor: theme.palette.primary.main,
          },
        }),

        "&.Mui-disabled": {
          color: theme.palette.intent?.disabled.text,
          backgroundColor: theme.palette.intent?.disabled.background,
        },

        variants: [
          {
            props: { invert: "true" },
            style: {
              color: theme.palette.icon?.primary,
              backgroundColor: theme.palette.background?.tertiary,

              ...applyPlatformAwareInteractionStyles({
                hoverStyle: {
                  backgroundColor: theme.palette.background?.secondary,
                },
                defaultStyle: {
                  backgroundColor: theme.palette.background?.tertiary,
                },
              }),
            },
          },
          {
            props: { outlineThickness: "thick" },
            style: {
              outlineWidth: theme.borderWidth?.thick,
            },
          },
        ],
      }),

      // Used for "outlined" variant
      colorSecondary: ({ theme }) => ({
        color: theme.palette.icon?.primary,
        outlineColor: theme.palette.border?.primary,
        outlineWidth: theme.borderWidth?.regular,
        outlineStyle: "solid",
        backgroundColor: theme.palette.background?.tertiary,
        boxShadow: theme.shadows[2],

        ...applyPlatformAwareInteractionStyles({
          hoverStyle: {
            outlineColor: theme.palette.primary.main,
            backgroundColor: theme.palette.background?.tertiary,
          },
          defaultStyle: {
            outlineColor: theme.palette.border?.primary,
          },
        }),

        "&.Mui-disabled": {
          color: theme.palette.icon?.disabled,
          outlineColor: theme.palette.border?.subtle,
          backgroundColor: theme.palette.background?.tertiary,
        },

        variants: [
          {
            props: { outlineThickness: "thick" },
            style: {
              ...applyPlatformAwareInteractionStyles({
                hoverStyle: {
                  outlineWidth: theme.borderWidth?.thick,
                  outlineColor: theme.palette?.border?.subtleInvert,
                },
              }),
            },
          },
          {
            props: { invert: "true" },
            style: {
              color: theme.palette.text?.invertPrimary,
              outlineColor: theme.palette.border?.primaryInvert,
              backgroundColor: "transparent",
              boxShadow: "none",

              "&.Mui-disabled": {
                color: theme.palette.intent?.disabled.icon,
                outlineColor: theme.palette.border?.primaryInvert,
                backgroundColor: "transparent",
              },

              ...applyPlatformAwareInteractionStyles({
                hoverStyle: {
                  outlineColor: theme.palette.text?.invertPrimary,
                  backgroundColor: "transparent",
                },
                defaultStyle: {
                  color: theme.palette.text?.invertPrimary,
                  outlineColor: theme.palette.border?.primaryInvert,
                },
              }),
            },
          },
        ],
      }),
    },
  },
};
