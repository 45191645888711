import { type ThemeOptions } from "@mui/material";

export const muiThemeTypography: ThemeOptions["typography"] = (palette) => ({
  allVariants: {
    fontFamily:
      '"PPNeueMontreal", "SF Pro", -apple-system, BlinkMacSystemFont, "Helvetica Neue", "Roboto", sans-serif',
    color: palette.text.primary,
    fontWeight: 400,
    lineHeight: "inherit",
  },
  h1: {
    fontSize: "3rem", // 48px
    fontWeight: 400,
    lineHeight: 1.1, // 52.8px
    letterSpacing: "-0.03rem", // -0.48px
  },
  h2: {
    fontSize: "2.5rem", // 40px
    fontWeight: 400,
    lineHeight: 1.2, // 48px
    letterSpacing: "-0.025rem", // -0.4px
  },
  h3: {
    fontSize: "2rem", // 32px
    fontWeight: 400,
    lineHeight: 1.09, // 35px
  },
  h4: {
    fontSize: "1.5rem", // 24px
    fontWeight: 500,
    lineHeight: 1.21, // 29px
    letterSpacing: "-0.015rem", // -0.24px
  },
  h5: {
    fontSize: "1.25rem", // 20px
    fontWeight: 500,
    lineHeight: 1.2, // 24px
    letterSpacing: "-0.0125rem", // -0.2px
  },
  h6: {
    fontSize: "1.125rem", // 18px
    fontWeight: 500,
    lineHeight: 1.1111, // 20px
    letterSpacing: "-0.01125rem", // -0.18px
  },

  body1: {
    fontSize: "1rem", // 16px
    lineHeight: 1.0625, // 17px
    letterSpacing: "-0.01rem", // -0.16px
  },
  body2: {
    fontSize: "0.875rem", // 14px
    lineHeight: 1.21, // 17px
    letterSpacing: "-0.00875rem", // -0.14px
  },
  caption: {
    fontSize: "0.75rem", // 12px
    lineHeight: 1.21, // 14.5px
    letterSpacing: "-0.0075rem", // -0.12px
  },
  fontWeightLight: 300,
  fontWeightRegular: 400,
  fontWeightMedium: 500,
  fontWeightBold: 500,
});
